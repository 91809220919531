import {
  Grommet,
  Button,
  Header,
  Nav,
  Card,
  CardFooter,
  CardBody,
  Box,
  Text,
  WorldMap,
  Stack,
  Heading,
  Avatar,
  ResponsiveContext,
  Menu,
  TextInput,
  TextArea,
  FileInput,
  Paragraph,
  List,
} from "grommet";
import React, { useCallback, useEffect, useState } from "react";
import "../styles/global.css";
import { Menu as MenuIcon, Linkedin, Twitter, Send } from "grommet-icons";
import { grommet, ThemeType } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import AvatarImage from "../images/aleksandra.jpg";
import BackImage from "../images/back.jpg";
import { RiseLoader } from "react-spinners";
import { graphql, useStaticQuery } from "gatsby";
import Cards from "./Cards";
import Contact from "./Contact";

const theme: ThemeType = deepMerge(grommet, {
  global: {
    font: {
      family: "Merriweather",
    },
    colors: {
      brand: "#112D4E",
      "accent-1": "#F9F7F7",
      "accent-2": "#3F72AF",
      "accent-3": "#DBE2EF",
    },
  },
});
React;

function ContactForm() {
  return (
    <Box align="center">
      <Heading id="porada">Porada prawna online</Heading>
      <Card width="xlarge" background="light-1">
        <form
          name="contact"
          netlify-honeypot="bot-field"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <CardBody pad="medium">
            <Box gap="medium" flex wrap direction="row">
              <Box style={{ flexBasis: 400 }} align="center" flex fill>
                <Paragraph style={{ fontWeight: "bold" }} textAlign="justify">
                  Jak uzyskać poradę prawną bez wychodzenia z domu?
                </Paragraph>
                <Box fill flex>
                  <Paragraph fill>
                    1. Skontaktuj się z nami za pośrednictwem formularza lub
                    mailowo wysyłając wiadomość na adres:
                    biuro@kostuchkancelaria.pl
                  </Paragraph>
                  <Paragraph fill>
                    2. Po zapoznaniu się ze zgłoszeniem możemy poprosić Cię o
                    przesłanie dodatkowej dokumentacji.
                  </Paragraph>
                  <Paragraph fill>
                    3. Po określeniu przedmiotu porady wycenimy Twoje zlecenie.
                  </Paragraph>
                  <Paragraph fill>
                    4. Dokonaj płatności na numer konta
                    <br />
                    <b>47 2490 0005 0000 4500 5748 2799</b>,
                    <br /> w tytule przelewu podając swoje imię i nazwisko.
                  </Paragraph>
                  <Paragraph fill>
                    5. Porada zostanie zrealizowana po zaksięgowaniu wpłaty na
                    naszym koncie.
                  </Paragraph>
                </Box>
              </Box>
              <Box style={{ flexBasis: 400 }} flex gap="small">
                <Box direction="row">
                  <TextArea
                    name="content"
                    required
                    rows={7}
                    style={{ backgroundColor: "white" }}
                    placeholder="Opisz swój problem *"
                    fill
                    resize={false}
                  />
                </Box>
                <Box direction="row">
                  <TextInput
                    name="name"
                    required
                    placeholder="Imię i nazwisko *"
                    style={{ backgroundColor: "white" }}
                  />
                </Box>
                <Box direction="row">
                  <TextInput
                    type="email"
                    name="email"
                    required
                    placeholder="Adres e-mail *"
                    style={{ backgroundColor: "white" }}
                  />
                </Box>
                <Box direction="row">
                  <TextInput
                    type="tel"
                    name="telephone"
                    placeholder="Numer telefonu"
                    style={{ backgroundColor: "white" }}
                  />
                </Box>
                <FileInput
                  messages={{
                    dropPrompt: "Przeciągnij tu plik lub",
                    browse: "Wybierz plik z dysku",
                  }}
                  name="file"
                />
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" background="light-2">
            <Box flex align="center" direction="row" fill>
              <Text
                margin={{ left: "small" }}
                size="small"
                color="status-critical"
              >
                * Pola wymagane
              </Text>

              <Button
                type="submit"
                style={{ marginLeft: "auto" }}
                alignSelf="end"
                primary
                label="Wyślij"
                icon={<Send />}
                hoverIndicator
              />
            </Box>
          </CardFooter>
        </form>
      </Card>
    </Box>
  );
}

function fetchStyle(url: string) {
  return new Promise((resolve, _reject) => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.onload = () => {
      resolve(undefined);
    };
    link.href = url;
    document.head.appendChild(link);
  });
}

function NavMenu() {
  const size = React.useContext(ResponsiveContext);
  return (
    <Header pad="small" background="brand">
      <Box alignSelf="center">
        <Text style={{ fontSize: "17px" }}>Aleksandra Kostuch </Text>
        <Text style={{ fontSize: "13px", color: "#ababab" }}>
          Kancelaria Radcy Prawnego
        </Text>
      </Box>
      {size === "small" || size === "medium" ? (
        <Nav direction="row">
          <Button
            target="_blank"
            href="https://www.linkedin.com/in/aleksandra-kostuch"
            icon={<Linkedin />}
          />
          <Button
            target="_blank"
            href="https://twitter.com/kostuchkanc"
            icon={<Twitter />}
          />
          <Menu
            icon={<MenuIcon />}
            items={[
              {
                label: "O kancelarii",
                onClick: () => {
                  window.location.hash = "";
                  setTimeout(() => {
                    window.location.hash = "okancelarii";
                  }, 0);
                },
              },
              {
                label: "Porada prawna online",
                onClick: () => {
                  window.location.hash = "";
                  setTimeout(() => {
                    window.location.hash = "porada";
                  }, 0);
                },
              },
              {
                label: "Oferta",
                onClick: () => {
                  window.location.hash = "";

                  setTimeout(() => {
                    window.location.hash = "uslugi";
                  }, 0);
                },
              },
              {
                label: "Kontakt",
                onClick: () => {
                  window.location.hash = "";

                  setTimeout(() => {
                    window.location.hash = "kontakt";
                  }, 0);
                },
              },
            ]}
          ></Menu>
        </Nav>
      ) : (
        <Nav alignContent="center" justify="end" flex direction="row">
          <Button
            style={{ lineHeight: "40px" }}
            label="O kancelarii"
            href="#okancelarii"
          />
          <Button
            style={{ lineHeight: "40px" }}
            label="Porada prawna online"
            href="#porada"
          />
          <Button
            style={{ lineHeight: "40px" }}
            label="Oferta"
            href="#uslugi"
          />
          <Button
            style={{ lineHeight: "40px" }}
            label="Kontakt"
            href="#kontakt"
          />

          <Button
            alignSelf="center"
            target="_blank"
            href="https://www.linkedin.com/in/aleksandra-kostuch"
            icon={<Linkedin />}
          />
          <Button
            alignSelf="center"
            target="_blank"
            href="https://twitter.com/kostuchkanc"
            icon={<Twitter />}
          />
        </Nav>
      )}
    </Header>
  );
}

function Map() {
  const size = React.useContext(ResponsiveContext);
  return (
    <Stack fill>
      <Box overflow="hidden" justify="center" fill>
        <WorldMap
          fill="vertical"
          alignSelf="center"
          places={[
            {
              name: "Krakow",
              location: [50.0647, 19.945],
              color: "accent-2",
            },
          ]}
        />
      </Box>
      <Box justify="center" fill>
        <Text alignSelf="center" size={size !== "small" ? "30px" : "20x"}>
          Rozwiążemy Twoją{" "}
          <Text size={size !== "small" ? "50px" : "35px"}>sprawę</Text>
        </Text>
      </Box>
    </Stack>
  );
}

export default function IndexPage() {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [backgroundUrl, setBackgroundUrl] = useState<string | null>(null);

  const init = useCallback(async () => {
    const image = new Image();
    await new Promise((resolve) => {
      image.addEventListener("load", () => {
        setBackgroundUrl(BackImage);
        resolve(undefined);
      });
      image.src = BackImage;
    });

    await fetchStyle("https://fonts.googleapis.com/css?family=Merriweather");
    await new Promise((resolve) => {
      setTimeout(() => resolve(undefined), 500);
    });
    setLoaded(true);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query BioQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "bio" } } }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {!loaded && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <RiseLoader color="#112D4E" />
        </div>
      )}
      <Grommet
        style={{
          opacity: loaded ? 1 : 0,
          transition: "opacity 1s ease-in",
        }}
        full
        theme={theme}
      >
        <Box fill flex>
          <NavMenu />
          <Box
            flex
            style={{
              scrollBehavior: "smooth",
            }}
            overflow="auto"
          >
            <Stack id="okancelarii" guidingChild="last">
              <Box
                style={{
                  WebkitMaskImage:
                    "linear-gradient(to top,  rgba(255,255,255,0) 30%,rgba(255,255,255,1) 90%)",
                }}
              >
                {backgroundUrl && (
                  <Box height="large" background={`url(${backgroundUrl})`} />
                )}
              </Box>

              <Box gap="large" align="center">
                <Box margin="medium" justify="center" flex wrap direction="row">
                  <Avatar margin="medium" size="300px" src={AvatarImage} />
                  <Card
                    elevation="medium"
                    background="white"
                    width="large"
                    pad="medium"
                    margin="small"
                  >
                    <Heading margin="0" level="4">
                      {allMarkdownRemark.edges[0].node.frontmatter.title}
                    </Heading>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: allMarkdownRemark.edges[0].node.html,
                      }}
                      textAlign="justify"
                    />
                  </Card>
                </Box>

                <ContactForm />
                <Cards />

                <Map />
              </Box>
            </Stack>
            <Contact />
          </Box>
        </Box>
      </Grommet>
    </>
  );
}
